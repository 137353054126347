import React, { useState } from "react";
import team from "../../assets/team.jpg";
import growth from "../../assets/growth.jpg";
import techno from "../../assets/tech.jpg";
import company from "../../assets/company.jpg";
const AboutToggle = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <div className="p-4  bg-white text-black ">
        {/* <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3 text-sm mt-10">
          <button
            className={`${
              toggleState === 1 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-purple-600 uppercase rounded hover:scale-105 duration-300 font-semibold`}
            onClick={() => toggleTab(1)}
          >
            The Company
          </button>
          <button
            className={`${
              toggleState === 2 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-pink-600 uppercase hover:scale-105 duration-300 font-semibold`}
            onClick={() => toggleTab(2)}
          >
            Our Mission
          </button>

          <button
            className={`${
              toggleState === 3 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-red-600 uppercase hover:scale-105 duration-300 font-semibold`}
            onClick={() => toggleTab(3)}
          >
            our Vision
          </button>

          <button
            className={`${
              toggleState === 4 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-blue-400 uppercase hover:scale-105 duration-300 font-semibold`}
            onClick={() => toggleTab(4)}
          >
            our team
          </button>
        </div> */}

        <div className=" my-10">
          <div className=" rounded-2xl ">
            <div
              className={`${1 === 1 ? "block" : "hidden"} text-black`}
            >  
            <h1 className="text-center px-4 sm:pl-28 mb-10 py-12 sm:text-3xl font-[Arial] font-semibold">
           The Company
          </h1>
              <div className="rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2">
                <div>
                  <img className="max-h-96" src={company} alt="" />
                </div>
                <div className="flex flex-col gap-3 w-full md:justify-start md:items-start justify-center items-center text-start">
                  <p className=" md:text-xl text-center md:text-start">
                    A software solution provider company with a view to digitise
                    the day to day activities of the organisations varying from
                    all the major sectors of business houses. We are committed
                    to provide the best solutions with prompt after sales
                    services and training. Apart from software development we
                    also deal in Web Solutions, Web Designing, GPS Vehicle
                    Tracking, Fleet Management software and all Logistic
                    Softwares.
                  </p>

                  <ul className="flex flex-col gap-3 list-disc md:text-xl">
                    <li>Quality, innovation, results. </li>
                    <li> Passionate collaboration.</li>
                    <li> Social responsibility.</li>
                    <li>Ongoing learning and development.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={`${2 === 2 ? "block" : "hidden"}`}>
            <h1 className="text-center px-4 sm:pl-28 mb-10 py-12 sm:text-3xl font-[Arial] font-semibold">
            Our Mission
          </h1>
              <div className="rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2">
                <div className="flex flex-col gap-3">
                  <p className="md:text-left text-center">
                    At Anshi Solutions, our mission is to help businesses and
                    individuals succeed through exceptional website design and
                    coaching services. We believe that a great website is more
                    than just a digital presence; it's an extension of your
                    brand, your voice, and your identity. Our team of experts is
                    dedicated to creating websites that are not only visually
                    stunning but also highly functional and effective.
                  </p>
                  <ul className="flex flex-col gap-3  md:text-xl md:text-left text-center ">
                    <li> Empowering success through exceptional services. </li>
                    <li> Collaborative approach for customized solutions.</li>
                    <li>
                      {" "}
                      Focus on quality, results, and customer satisfaction.
                    </li>
                    <li>
                      {" "}
                      Operating with integrity and social responsibility.
                    </li>
                  </ul>
                </div>
                <div className="order-first md:order-none grid">
                  <img className="max-h-96  " src={growth} alt="" />
                </div>
              </div>
            </div>

            <div className={`${3 === 3 ? "block" : "hidden"}`}>
            <h1 className="text-center px-4 sm:pl-28 mb-10 py-12 sm:text-3xl font-[Arial] font-semibold">
            Our Vision
          </h1>
              <div className="rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2">
                <div>
                  <img className="max-h-96" src={techno} alt="" />
                </div>
                <div className="flex flex-col gap-3 w-full md:justify-start md:items-start justify-center items-center text-start">
                  <p className=" md:text-xl text-center md:text-start">
                    At Anshi Solutions , we are committed to empowering
                    businesses and individuals to achieve their full potential
                    online. Our vision is to be the leading provider of website
                    design and coaching services, recognized for our dedication
                    to quality, innovation, and results.
                  </p>

                  <ul className="flex flex-col gap-3 list-disc md:text-xl">
                    <li>Quality, innovation, results. </li>
                    <li> Passionate collaboration.</li>
                    <li> Social responsibility.</li>
                    <li>Ongoing learning and development.</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className={`${4 === 4 ? "block" : "hidden"}`}>
              <div className="rounded-xl  grid grid-cols-1 md:grid-cols-2 justify-items-center items-center text-center gap-2">
                <div>
                  <p className="text-left">
                    Our team at our website consists of skilled and experienced
                    website designers, developers, and digital marketing experts
                    who are dedicated to providing our clients with premium
                    quality website design services and live coaching classes.
                    Our team members have a passion for creating beautiful and
                    functional websites that effectively communicate our
                    clients' brand message. They are committed to staying
                    up-to-date with the latest trends and technologies in the
                    industry, ensuring that we provide our clients with the best
                    possible service. Each team member brings unique skills and
                    expertise to the table, allowing us to deliver tailor-made
                    solutions that meet our clients' specific needs and exceed
                    their expectations. We work collaboratively to achieve our
                    mission and vision, while maintaining a positive and
                    supportive work environment.
                  </p>
                </div>
                <div>
                  <img className="max-h-96" src={team} alt="" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutToggle;
