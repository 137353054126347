import React, { useEffect } from "react";

import MainPageCard from "../components/MainPageCard";

import BelifInActionHome from "../components/BelifInActionHome";
import ServiceHome from "../components/ServiceHome";

import MainSwiperAbout from "../components/MainSwiperAbout";

import Testimonial from "../components/Testimonial";
import LatestBlog from "../components/MainPageGallary";
import Industries from "../components/Industries";
import Tehnologies from "../components/Tehnologies";
const HomePage = () => {
  return (
    <>
      {/* <Loader /> */}
      <div className="bg-[] w-full">
        <MainSwiperAbout />
      </div>

      {/* Services */}
      <ServiceHome />

      {/* Card */}
      <MainPageCard />
      <Tehnologies />
      <Industries />
      {/* Projects */}
      <BelifInActionHome />

      {/* <LatestBlog /> */}

      {/* <Testimonial /> */}
    </>
  );
};

export default HomePage;
