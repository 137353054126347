

import React from 'react'
import { BiLogInCircle } from 'react-icons/bi';

const ButtonLogout = () => {
    return (
        <>
            <div className="grid gap-8 items-start justify-center">
                <div className="relative group">

                    <div className=" animate-tilt absolute inset-0 bg-gradient-to-r from-pink-600 to-purple-600 rounded-md blur-xl
                        group-hover:opacity-100 opacity-75 transition duration-200
                        "></div>

                    <button className='relative px-4 py-2 bg-black rounded-md leading-none flex items-center space-x-5 divide-x divide-gray-600' >

                        <span className="flex items-center space-x-2">
                            <BiLogInCircle className='text-pink-600' />
                            <span className='group-hover:text-indigo-400 text-gray-100 duration-200' >Log Out</span>
                        </span>

                    </button>
                </div>
            </div>
        </>
    )
}

export default ButtonLogout
