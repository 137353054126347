import React from "react";
import { FaGreaterThan } from "react-icons/fa";

import { Link } from "react-router-dom";
import chain from "../assets/Industries/chain.jpg";
import consultent from "../assets/Industries/consultent.jpg";
import logis from "../assets/Industries/logis.png";
import payroll from "../assets/Industries/payroll.webp";
import fleet from "../assets/Industries/fleet.jpg";
import ERP from "../assets/Industries/ERP.jpg";
import rmc from "../assets/Industries/rmc.png";
import transport from "../assets/Industries/transport.png";
import { Image } from "antd";
const Solution = () => {
  return (
    <div>
      <div
        className="bg-[url(inner_page_banner.jpg)  ] font-[Arial] py-20 bg-center text-white "
        style={{ backgroundImage: "url(inner_page_banner.jpg)" }}
      >
        <div className=" pl-10 sm:pl-44 sm:py-5">
          <button className="font-[550] text-[40px] ">Solutions</button>
          <div className="flex text-base gap-2 ">
            <button className="text-sky-400 "> Home</button>{" "}
            <FaGreaterThan style={{ marginTop: "6px" }} />{" "}
            <button> Solution </button>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-center ">
        <div className="flex flex-col justify-center items-center bg-slate-100 max-w-full">
         
          <div className="flex flex-col items-center justify-evenly gap-2 my-12 lg:px-10 lg:flex-row space-y-8 lg:space-y-0 ">
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">
             
              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-sm tracking-tight font-semibold text-green-600    ">
                Consignment Booking (Percel parchune)
              </h1>
              <p className="text-black mt-3 text-letf w-[90%] lg:w-[100%]">
                Multi-Company / Branch / User <br /> Booking / GR / LR <br />{" "}
                Challan / GDM / Dispatch <br /> Crossing Entry / Challan <br />
                Party Bill / Bill Settlement
                <br /> Delivered / Undelivered Godown Status <br /> Delivery
                Money Reciept
                <br /> Service Tax Management <br /> Financial Accounting <br />{" "}
                Trip Statement <br /> Daily and Monthly Delivery Register <br />{" "}
                Challan / Booking / Crossing Register{" "}
              </p>
              <img
                src="https://aanshisolutions.com/Image/Software-management.png"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='/contact'>

                <button className="text-sm px-2 py-1 font-semibold bg-blue-500 text-white rounded  ">
                  Request A Demo
                </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">
             
              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-sm tracking-tight font-semibold text-green-600    ">
                Commission Agent Thrid Party
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                Multi-Company / Branch / User <br />
                Booking / GR /
                <br />
                Challan / GDM / Dispatch Crossing Entry / Challan
                <br />
                Party Bill / Bill Settlement
                <br /> Delivered / Undelivered Godown Status
                <br />
                Self / Hited / Attached Vehicle Management
                <br /> Freight Payment
                <br /> Commission / Vasuli Register
                <br />
                Financial Acconting
                <br />
                Daily and Monthly Delivery Register
                <br />
                Challan / Booking / Crossing Register{" "}
              </p>
              <img
                src="https://aanshisolutions.com/Image/icon_partners-325.jpg"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='/contact'>

                <button className="text-sm px-2 py-1 font-semibold bg-blue-500 text-white rounded  ">
                  Request A Demo
                </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-left items-center p-7 bg-white shadow-md rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">
             
              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-sm font-[Arial] tracking-tight font-semibold text-green-600    ">
                Vehicle Management
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {" "}
                Vehicle Document Management
                <br /> Fuel Management
                <br /> Tyre Management
                <br /> Spare and Tyre Inventory
                <br /> Inventory Management
                <br /> Financial Accounting
                <br /> Daily and Routine Expenses
                <br /> Scheduled and Routine Service Setup
                <br /> GPS Vehicle Tracking
                <br /> Document/Service Other Reminders
                <br /> Driver Management
                <br /> Driver Licence Reminders
              </p>
              <img
                src="https://aanshisolutions.com/Image/trucks.png"
                className="rounded-full mt-10"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='/contact'>

                <button className="text-sm px-2 py-1 font-semibold bg-blue-500 text-white rounded  ">
                  Request A Demo
                </button>
                </Link>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center p-7 shadow-md  bg-white rounded-xl w-[90%] h-[550px] md:w-[70%] lg:w-[30%] overflow-hidden">
             
              <div className="flex flex-col -mt-20 -mr-[352px] hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <h1 className="text-sm text-left tracking-tight font-semibold text-green-600    ">
                Full Truck / Tanker / Trailor
              </h1>
              <p className="text-black mt-3 text-left w-[90%] lg:w-[100%]">
                {" "}
                Multi-Company / Branch / User
                <br />
                Booking / GR / LR
                <br />
                Challan / GDM / Despatch
                <br />
                Crossing Entry / Challan
                <br />
                Party Bill / Bill Settlement
                <br />
                Delivered / Undelivered Godown Status
                <br />
                Self / Hited / Attached Vehicle Management
                <br />
                Freight Payment
                <br />
                Commission / Vasuli Register
                <br />
                Financial Acconting
                <br />
                Daily and Monthly Delivery Register
                <br />
                Challan/Booking / Crossing Register
              </p>
              <img
                src="https://aanshisolutions.com/Image/images.png"
                className="rounded-full mt-4"
                alt=""
              />
              <div className="flex flex-col -ml-[352px] mt-72 absolute hidden xl:block">
                <div className="w-2 h-14 bg-blue-900"></div>
                <div className="w-2 h-14 bg-green-500"></div>
              </div>
              <div className="flex justify-center gap-2 mt-2">
                <Link to='/contact'>

                <button className="text-sm px-2 py-1 font-semibold bg-blue-500 text-white rounded  ">
                  Request A Demo
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
  

  <div class="flex items-center justify-center text-center my-10  w-[100%]">
            <div class="flex flex-col items-center justify-center rounded-tr-full rounded-bl-full w-full bg-gray-100">
              <div class="flex flex-col p-2 m-0 w-full">
              <div class="text-xl md:text-3xl font-medium my-5 uppercase">   </div>
   {/* <div className="text-center">Fastest  service with best price! </div> */}

                 <div class="sm:grid sm:grid-cols-6 sm:gap-3 md:grid-cols-3 md:grid lg:grid-cols-5 lg:grid grid-cols-2 grid  gap-4 p-2">
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full overflow-hidden bg-white">
                      <Image
                      preview={false}
                        src={transport}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Transport Management

                    </div>
                   
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  border-1  rounded-2xl bg-white hover:scale-105 w-[100%] ">
                    <div class="sm:h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                      preview={false}
                        src={chain}
                        alt=""
                        class=""
                        width={100}
                       height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Dealer supply chain management
                    </div>
                 
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl border-collapse border-1  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                        src={payroll }
                        preview={false}
                        alt=""
                        class=""
                        width={100}
                       height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                      />
                    </div>

                    <div className="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Payroll Management
                    </div>
                 
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                        src={ERP}
                        preview={false}
                        alt=""
                        class=""
                        width={100}
                       height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      
                    ERP

                    </div>
                
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full overflow-hidden bg-white">
                      <Image
                      preview={false}
                        src={fleet}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Fleet Management

                    </div>
                   
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full overflow-hidden bg-white">
                      <Image
                      preview={false}
                        src={rmc}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    RMC Activities management

                    </div>
                   
                    
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full overflow-hidden bg-white">
                      <Image
                      preview={false}
                        src={logis}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Factory Logistics

                    </div>
                   
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
    </div>
  );
};

export default Solution;
