import React from "react";
import { Link, Outlet } from "react-router-dom";
import Whyonlinehoja from "../components/Career/Whyonlinehoja";
import CertifiedPage from "../components/Career/CertifiedPage";
import CareerChoose from "../components/Career/CareerChoose";
import { FaGreaterThan } from "react-icons/fa";
import SwiperAbout from "../components/About/SwiperAbout";
import Testimonial from "../components/Testimonial";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikControl from "../components/ReusableFormField/FormikControl";
const Career = () => {
  // const { data } = useGetAllCoursesQuery();
  // const [enquiry] = usePostEnquiryMutation();
  // console.log(data, "20");

  const course = [2, 2]?.map(({ courseName }) => {
    return { key: courseName, value: courseName };
  });
  const initialValues = {
    fname: "",
    email: "",
    phone: "",
    courseName: "",
    message: "",
  };

  const onSubmit = async (values, onSubmitProps) => {
    console.log("Form Data", values); // Main Form Data
    onSubmitProps.setSubmitting(false); // This set isSubmittion false after save

    onSubmitProps.resetForm(); // Reset Form on submit
    // mutate(values)
  };

  const validationSchema = Yup.object({
    fname: Yup.string()
      .min(2, "Name must be at least 2 letters")
      .max(25, "Name must be at most 25 characters")
      .required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    phone: Yup.string()
      .matches(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, "Invalid mobile number")
      .required("Required"),
    resume: Yup.string().required("Required"),
    message: Yup.string()
      .min(10, "Message must be at least 10 letters")
      .max(200, "Message must be at most 200 characters")
      .required("Required"),
  });
  return (
    <div>
      <div>
        <div
          className="bg-[url(inner_page_banner.jpg)  ] py-20 bg-center text-white "
          style={{ backgroundImage: "url(inner_page_banner.jpg)" }}
        >
          <div className=" pl-10 sm:pl-44 sm:py-5">
            <button className="font-[550] text-[40px] ">Career</button>
            <div className="flex text-base gap-2 ">
              <button className="text-sky-400 "> Home</button>{" "}
              <FaGreaterThan style={{ marginTop: "6px" }} />{" "}
              <button> Career </button>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b px-2 sm:px-44 from-orange-300 to-[#04619F] w-full ">
          <div className="grid text-white md:col-span-2 p-4  md:grid-cols-3 gap-5 ">
            <div className="md:col-span-2 justify-center flex flex-col items-center md:items-start ">
              <h1 className="md:text-6xl text-3xl">Work @ Aanshi Solutions</h1>
              <span>
                We are always looking for talented and competent individuals who
                are good team players with high ambitions, who love challenges
                and have a passion to excel. If you want to join our company and
                be a part of it then you can apply us- just by clicking on apply
              </span>
              <span></span>
            </div>

            {/* Form */}

            <div className="order-3 md:row-span-2 justify-items-center grid w-full ">
              <div className="grid p-5 bg-transparent  duration-300 hover:shadow-xl rounded gap-3  w-full">
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  validateOnMount
                  enableReinitialize
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form className="flex flex-col gap-2   justify-center items-center  ">
                        {/* <input className='rounded' type="" placeholder='' /> */}
                        <FormikControl
                          control="input"
                          label="Enter Name"
                          type="text"
                          name="fname"
                          placeholder="Enter Name"
                        />
                        {/* Email */}
                        <FormikControl
                          control="input"
                          label="Email"
                          type="email"
                          name="email"
                          placeholder="Email address"
                        />
                        <FormikControl
                          control="input"
                          label="Phone Number"
                          type="number"
                          name="phone"
                          placeholder="Phone Number"
                        />

                        {/* Message */}
                        <FormikControl
                          control="textarea"
                          label="Message"
                          name="message"
                          placeholder="Message"
                        />

                        {/* Flie */}
                        <FormikControl
                          control="file"
                          label="Resume"
                          name="resume"
                          placeholder="Resume"
                        />

                        <button
                          type="submit"
                          className="border bg-blue-700  p-2 px-6 rounded-md font-thin hover:text-xl duration-300 text-white active:bg-green-800 disabled:bg-gray-500 disabled:cursor-not-allowed "
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Submit
                        </button>

                        {/* <textarea name="" placeholder='Message' id="" cols="30" rows="4"></textarea> */}
                        {/* <button className='bg-gradient-to-r  to-blue-800 px-4 py-2 rounded' >submit</button> */}
                      </Form>
                    );
                  }}
                </Formik>
                {/* <input className='rounded' type="email" placeholder='Enter Email' /> */}
              </div>
            </div>

            <div className="md:order-3 md:col-span-2 ">
              {/* <div className="flex justify-evenly gap-3">
              <button className="px-4 py-2  bg-gradient-to-r from-black to-blue-700 rounded w-full border">
                <Link to="/signup/login" >
                  Enroll Now
                </Link>
              </button>
              <button className="px-4 py-2  bg-gradient-to-r from-black to-blue-700 rounded w-full border">
                Try For Free
              </button>
            </div> */}
            </div>
          </div>
        </div>
        {/* <SwiperAbout /> */}
        {/* <Testimonial /> */}
      </div>
    </div>
  );
};

export default Career;
