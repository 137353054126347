import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavButton from "./SwiperNavButton";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper";

const Testimonial = () => {
  return (
    <div>
      <div className="flex justify-center  bg-bgimg  p-4">
        <div className="flex flex-col justify-left items-left ">
          <div className="flex flex-col md:flex-row max-w-7xl justify-left items-left ">
            <div className="overflow-hidden md:w-3/4  flex justify-left bg-transparent  ">
              <div className="flex flex-col text-left md:flex-row items-left justify-left md:w-3/4  ">
                <div className=" flex flex-col items-left justify-left  py-2">
                  <div className="text-3xl px-6 font-bold text-white ">
                    Testimonials
                  </div>
                  <div className="text-md text-white px-6 font-medium   m-2">
                    See What People are Saying
                  </div>
                  <div className="flex flex-col md:flex-row   items-center justify-center   ">
                          <div className="pl-0 pt-0 w-24 m-auto  rounded-full ">
                            <img
                              src="https://assets.website-files.com/619e8d2e8bd4838a9340a810/647c106477f8934b266ba39c_profile-picture-og.webp"
                              alt=""
                              className="rounded-full"
                            />
                          </div>
                          <div className="flex flex-col w-full md:w-2/3 text-left ">
                            <div className=" font-normal text-white m-0 text-left italic">
                              {" "}
                              Tailblocks provides best Tailwind CSS Components
                              and Blocks to create an unique websites within
                              minutes. It has upto 60+ free components for
                              front-end Web Development.
                            </div>
                            <div className="font-bold text-2xl text-blue-500 ">
                              John Doe
                            </div>
                            <div className="text-stone-500 text-sm text-left   ">
                              <a href="#">CEO, Tailblocks</a>
                            </div>
                          </div>

                        </div>
                

                        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
