import React, { useEffect } from "react";
// import block from "../assets/block.png";
// import node from "../assets/node.png";
import digital from "../assets/digital";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import react from "../assets/tehnologies/react.png";
import redux from "../assets/tehnologies/redux.png";
import node from "../assets/tehnologies/node.png";
import mongo from "../assets/tehnologies/mongo.png";
import django from "../assets/tehnologies/django.png";
import aspnet from "../assets/aspnet.png";
import vbnet from "../assets/tehnologies/vbnet.jpg";
import ps from "../assets/tehnologies/ps.png";
import corel from "../assets/tehnologies/corel.png";
import wordpress from "../assets/tehnologies/wordpress.png";
import sql from "../assets/tehnologies/sql.png";
import mysql from "../assets/tehnologies/mysql.png";
import customsoftware from "../assets/customsoftware.webp";
import graphic from "../assets/graphic-design4.png";
// import { Link } from "react-router-dom";
import websiteservice from "../assets/websiteservice.png";
// import { useGetTechnoQuery } from "../services/profile";
import Loader from "./Loader";
import { Image } from "antd";

const Tehnologies = () => {
  // const { data, isLoading } = useGetTechnoQuery();
  // console.log(data, "15");
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-100vh",
        opacity: 0,
      });
    }
  }, [animation, inView]);

  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <motion.div className="flex pb-16  items-center justify-center bg-gray-200 flex-col py-2  ">
          <div class="flex items-center justify-center text-center  w-[100%]">
            <div class="flex flex-col items-center justify-center rounded-tr-full rounded-bl-full w-full bg-gray-100">
              <div class="flex flex-col p-2 m-2 w-full">
              <div class="text-xl md:text-3xl font-medium my-5 uppercase"> Technologies  </div>
   {/* <div className="text-center">Fastest  service with best price! </div> */}

                 <div class="sm:px-20 sm:grid sm:grid-cols-6 sm:gap-3 md:grid-cols-4 md:grid lg:grid-cols-6 lg:grid grid-cols-2 grid gap-2 ">
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full  bg-white">
                      <Image
                      preview={false}
                        src={aspnet}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    ASP.net (C#)
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full  bg-white">
                      <Image
                      preview={false}
                        src={vbnet}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    VB.net 
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl bg-white hover:scale-105 w-[100%] ">
                    <div class="sm:h-28 sm:w-32 m-auto pt-2  rounded-full bg-white">
                      <Image
                      preview={false}
                        src={react}
                        alt=""
                        class=""
                        width={80}
                       height={80}
                        // style={{borderRadius:"50%",margin:"auto"}}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    React.js
                    </div>
                 
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                        src={redux}
                        preview={false}
                        alt=""
                        class=""
                        width={100}
                       height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Redux
                    </div>
                 
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto   rounded-full bg-white">
                      <Image
                        src={node}
                        preview={false}
                        alt=""
                        class=""
                        width={100}
                       height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      
                    Node.js

                    </div>
                
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={mongo}
                        alt=""
                        class=""
                        width={100}
                       height={100}
                        // style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Mongo DB
                    </div>
                   
                    
                  </div>

                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={mysql}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    mysql
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={django}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Django 
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={ps}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Adobe photoshop
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={corel}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        // style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Corel Draw
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={wordpress}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Wordpress
                    </div>
                   
                    
                  </div>
                  <div class="flex-col  py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full bg-white">
                      <Image
                      preview={false}
                        src={sql}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        // style={{borderRadius:"50%",margin:"auto"}}
                        
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                    Sql server
                    </div>
                   
                    
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Tehnologies;
