import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../components/ReusableFormField/FormikControl";
import TextError from "../components/TextError";
// import { usePostContactMutation } from "../services/profile";
import { toast, ToastContainer } from "react-toastify";

const MainContactForm = () => {
  // const [contactUs] = usePostContactMutation();

  // Initail State

  const initialValues = {
    name: "",
    email: "",
    mobileNumber: "",
    subject: "",
    message: "",

  };

  const onSubmit = async (values, onSubmitProps) => {
    console.log("Form Data", values); // Main Form Data
    onSubmitProps.setSubmitting(false); // This set isSubmittion false after save
   
    onSubmitProps.resetForm(); // Reset Form on submit
    // mutate(values)
  };

  const validationSchema = Yup.object({
    name: Yup.string().min(3, 'Name must be at least 3 letters').max(25, 'Text must be at most 25 characters').required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string().min(5, 'Subject must be at least 5 letters').max(100, 'Subject must be at most 100 characters').required("Required"),
    mobileNumber: Yup.string().matches(/^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/, 'Invalid mobile number').required("Required"),
    message: Yup.string().min(10, 'Message must be at least 10 letters').max(200, 'Message must be at most 200 characters').required("Required"),
  });

  return (
    <>
      <ToastContainer />
      <div className=" bg-white">
        

        <div className="md:w-11/12 w-full m-auto    m border-t-0">
          <div className="p-4">
            <div className=" text-center ">
              <h1 className="text-3xl " >Contact Us</h1>
             
             
            </div>

            {/* Form */}
            <div className=" w-full h-full mt-10">
              <div className="flex flex-col gap-4 md:p-5">
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  validateOnMount
                  enableReinitialize
                >
                  {(formik) => {
                    return (
                      <Form className="flex flex-col gap-6   justify-center items-center  ">
                        <div className="grid md:grid-cols-2 grid-cols-1 w-full gap-3 ">
                          {/* User Name */}
                          <FormikControl
                            control="input"
                            label="Full Name"
                            type="text"
                            name="name"
                            placeholder="Enter Full Name"
                          />

                          {/* Email */}
                          <FormikControl
                            control="input"
                            label="Email"
                            type="email"
                            name="email"
                            placeholder="Email address"
                          />
                        </div>

                        <div className="grid md:grid-cols-2 grid-cols-1 w-full gap-3 ">
                          {/* Phone Number */}
                          <FormikControl
                            control="input"
                            label="Phone Number"
                            type="number"
                            name="mobileNumber"
                            placeholder="mobile Number"
                          />

                          {/* Phone Number */}
                          <FormikControl
                            control="input"
                            label="Subject"
                            type="text"
                            name="subject"
                            placeholder="Subject"
                          />
                        </div>

                        {/* Email */}
                        <FormikControl
                          control="textarea"
                          label="message"
                          name="message"
                          placeholder="message"
                        />

                        {/* Submit Button */}
                        <button
                          type="submit"
                          className=" mt-4 disabled:bg-gray-400  bg-sky-300   rounded-md  p-2 text-white active:bg-green-800  disabled:cursor-not-allowed hover:text-xl duration-300"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Submit
                        </button>

                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContactForm;
