import React, { useState, useEffect } from "react";
import Images from "./images";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import { EffectCube } from "swiper";
import { EffectCoverflow } from "swiper";
import { EffectFlip } from "swiper";
import { EffectCards } from "swiper";
import { EffectCreative } from "swiper";
// import { useGetTeamQuery } from "../../services/profile";

// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
import SwiperNavButton from "../SwiperNavButton";

import sandeep from "../../assets/sandeep.jpg";

const SwiperAbout = () => {
  // const { data } = useGetTeamQuery();
  // console.log(data, "26");
  return (
    <>
      <div className="bg-white text-black pt-16 pb-10">
        <h1 className="text-letf px-4 sm:pl-28 py-4 sm:text-3xl font-[Arial] font-semibold">
          {" "}
          OUR TEAM
        </h1>
<div className="text-letf px-4 sm:pl-28 pb-10 mb-10">Our experts have been featured in press numerous times.

</div>
        <Swiper
          // install Swiper modules
          speed="800"
          autoplayDisableOnInteraction={false}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={20}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          parallax={true}
          // slidesPerView={perView}
          // navigation
          // pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSlideChange={() => console.log("slide change")}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          onSwiper={(swiper) => console.log(swiper)}
          className="bg-white w-11/12 m-auto "
        >
          {[1, 2, 2, 2]?.map(({ image, name, description }, index) => (
            <SwiperSlide key={index} className="   relative   group">
               <div class="flex-col px-0 py-2 shadow-xl rounded-2xl hover:scale-105 w-[90%] md:w-full m-auto ">
                        <div class="h-40 w-40 m-auto border-2 border-cyan-500 rounded-full overflow-hidden bg-gray-200">
                          <img
                            src="https://source.unsplash.com/300x300/?girl"
                            alt=""
                            class=""
                          />
                        </div>

                        <div class="text-lg text-center font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                          Elena Gilbert
                        </div>
                        <div class="italic text-center text-gray-500 text-sm">
                          HR, Tailblocks
                        </div>
                        <div class="flex justify-center my-2">
                          <a
                            href="#"
                            class="w-9 h-9 rounded-full border-cyan-500 border text-stone-500 hover:bg-cyan-500 hover:text-white mx-1 pt-1 inline-block"
                          >
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a
                            href="#"
                            class="w-9 h-9 rounded-full border-cyan-500 border text-stone-500 hover:bg-cyan-500 hover:text-white mx-1 pt-1 inline-block"
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                          <a
                            href="#"
                            class="w-9 h-9 rounded-full border-cyan-500 border text-stone-500 hover:bg-cyan-500 hover:text-white mx-1 pt-1 inline-block"
                          >
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a
                            href="#"
                            class="w-9 h-9 rounded-full border-cyan-500 border text-stone-500 hover:bg-cyan-500 hover:text-white mx-1 pt-1 inline-block"
                          >
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
            </SwiperSlide>
          ))}
          <div className="py-5">
            <SwiperNavButton />
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default SwiperAbout;
