import React from "react";
import software from "../assets/software.png"
import digital from "../assets/digital"
import mobileapp from "../assets/mobile-apps.jpg"
import website from "../assets/websiteservice.png"
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import MainPageCard from "../components/MainPageCard";
const Service = () => {
  return (
    <div className="mb-10">
      <div
        className="bg-[url(inner_page_banner.jpg)  ] py-20 bg-center text-white "
        style={{ backgroundImage: "url(inner_page_banner.jpg)" }}
      >
        <div className=" pl-10 sm:pl-44 sm:py-5 ">
          <button className="font-[550] text-[40px] ">Services</button>
          <div className="flex text-base gap-2 ">
            <button className="text-sky-400 "> Home</button>{" "}
            <FaGreaterThan style={{ marginTop: "6px" }} />{" "}
            <button> Service </button>
          </div>
        </div>
      </div>
      <MainPageCard />
      {/* <div class="flex justify-center">
        <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">
          <div class="overflow-hidden md:w-1/4 bg-white m-4  flex flex-col justify-center text-black   ">
            <div class="h-26 w-full overflow-hidden">
              {" "}
              <img
                src={software}
                alt=""
                class=""
              />{" "}
            </div>
            <div class="font-medium text-normal m-2">Softwere</div>
            <div class="text-sm m-2">
              When all seems to be against you, remember, a ship sometimes has
              to sail against the current, not with it.{" "}
            </div>
            <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
              {" "}
              VIEW SERVICE{" "}
            </button>
          </div>
          <div class="overflow-hidden md:w-1/4 bg-white m-4  flex flex-col justify-center text-black   ">
            <div class="h-26 w-full overflow-hidden">
              {" "}
              <img
                src={mobileapp}
                alt=""
                class=""
              />{" "}
            </div>
            <div class="font-medium text-normal m-2">Mobile Apps </div>
            <div class="text-sm m-2">
              It is a never ending battle of making your cars better and also
              trying to be better yourself.{" "}
            </div>
            <Link to={"/servicesdetail"}>
              <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
                {" "}
                VIEW SERVICE{" "}
              </button>
            </Link>
          </div>
          <div class="overflow-hidden md:w-1/4 bg-white m-4  flex flex-col justify-center text-black   ">
            <div class="h-26 w-full overflow-hidden">
              {" "}
              <img
                src={website}
                alt=""
                class=""
              />{" "}
            </div>
            <div class="font-medium text-normal m-2">Web Solutions</div>
            <div class="text-sm m-2">
              The moment you doubt whether you can fly, you cease forever to be
              able to do it.
            </div>
            <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
              {" "}
              VIEW SERVICE{" "}
            </button>
          </div>
          <div class="overflow-hidden md:w-1/4 bg-white m-4  flex flex-col justify-center text-black   ">
            <div class="h-26 w-full overflow-hidden">
              {" "}
              <img
                src={digital}
                alt=""
                class=""
              />{" "}
            </div>
            <div class=" font-medium text-normal m-2">Digital Marketing </div>
            <div class="text-sm m-2">
              Those freckles make you seem like a galaxy of stars, just waiting
              to be explored and loved.{" "}
            </div>
            <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
              {" "}
              VIEW SERVICE{" "}
            </button>
          </div>
        </div>
      </div> */}
      {/* <SwiperAbout /> */}
      {/* <Testimonial /> */}
    </div>
  );
};

export default Service;
