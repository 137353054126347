import React from "react";
import { FaGreaterThan } from "react-icons/fa";
import SwiperAbout from "../components/About/SwiperAbout";
import Testimonial from "../components/Testimonial";

const ServicesDetail = () => {
  return (
    <div className="scroll-smooth">
      <div
        className="bg-[url(inner_page_banner.jpg)  ] py-20 bg-center text-white "
        style={{ backgroundImage: "url(inner_page_banner.jpg)" }}
      >
        <div className=" pl-10 sm:pl-44 sm:py-5">
          <button className="font-[550] text-[40px] ">Services Detail</button>
          <div className="flex text-base gap-2 ">
            <button className="text-sky-400 "> Home</button>{" "}
            <FaGreaterThan style={{ marginTop: "6px" }} />{" "}
            <button> Services Detail </button>
          </div>
        </div>
      </div>

      <div className="flex  sm:flex-row flex-col ">
        <div className=" w-full sm:w-[70%]">
          <div class="flex justify-center">
            <div class="flex flex-col justify-center">
              <div class="flex flex-col md:flex-col max-w-full justify-center items-center ">
                <div class="overflow-hidden w-full m-4 shadow-sm flex justify-center">
                  <div class="flex flex-col md:flex-col items-center m-1">
                    <div class=" w-3/4 md:w-full overflow-hidden m-1">
                    
                      <img
                        src="https://source.unsplash.com/700x350/?beach,nature"
                        alt=""
                        class=""
                      />
                     
                      <div class="font-bold text-black text-xl m-2">
                      Mobile Apps
                      </div>
                      <div class="text-sm text-gray-500 mt-4 m-2">
                        <a href="#">
                          There is something special and unique about nature. It
                          has the ability to take us away from the hustle and
                          bustle of our everyday lives and transport us to a
                          different world. Whether it is the beauty of a sunset,
                          the majesty of a waterfall, or the serenity of a
                          forest, nature has a way of captivating our hearts and
                          souls.{" "}
                        </a>
                      </div>
                      <div class="flex cursor-pointer">
                        
                      <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
                  {" "}
                  VIEW SERVICE{" "}
                </button> 
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center ">
            <div class="flex flex-col md:flex-row max-w-7xl justify-center items-center ">
              <div class="overflow-hidden md:w-1/4 bg-white m-4  flex flex-col justify-center text-black   ">
                <div class="h-26 w-full overflow-hidden">
                  {" "}
                  <img
                    src="https://source.unsplash.com/random/500x400/?boat"
                    alt=""
                    class=""
                  />{" "}
                </div>
                <div class="font-medium text-normal m-2">Softwere</div>
                <div class="text-sm m-2">
                  When all seems to be against you, remember, a ship sometimes
                  has to sail against the current, not with it.{" "}
                </div>
                <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
                  {" "}
                  VIEW SERVICE{" "}
                </button>
              </div>
              <div class="overflow-hidden md:w-1/4 bg-white m-4  flex flex-col justify-center text-black   ">
                <div class="h-26 w-full overflow-hidden">
                  {" "}
                  <img
                    src="https://source.unsplash.com/random/500x400/?car"
                    alt=""
                    class=""
                  />{" "}
                </div>
                <div class="font-medium text-normal m-2">Mobile Apps </div>
                <div class="text-sm m-2">
                  It is a never ending battle of making your cars better and
                  also trying to be better yourself.{" "}
                </div>

                <button className="hover:text-white hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
                  {" "}
                  VIEW SERVICE{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="   w-full m-auto px-2 sm:w-[30%]">
          <div className=" font-semibold text-2xl mb-4 " > Search </div>
          <div class="relative flex my-4">
            <div class="absolute pointer-events-auto mt-3 mx-2 ">
              <svg
                class="absolute text-slate-400 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search"
              class="px-10  rounded-full bg-gray-100 focus:outline-none outline-none "
            />
          </div>
          <div className=" font-semibold text-2xl mb-4 " >GET A QUOTE</div>

          <p>
            An duo lorem altera gloriatur. No imperdiet adver sarium pro. No sit
            sumo lorem. Mei ea eius elitr consequ unturimperdiet.
          </p>

          <button className="hover:text-white  my-4 hover:bg-black bg-sky-400 p-2 text-white text-xs w-44 py-4 font-semibold ">
            {" "}
            VIEW SERVICE{" "}
          </button>
<div>


          <div className=" font-semibold text-2xl mb-4 " >OUR SERVICE</div>
          <div className="flex-col flex gap-1 ">
            <div>
              <button className="hover:text-white hover:bg-black bg-[#f6f6f6] p-2 text-black text-left text-xs w-44 py-4 font-semibold ">
                
                SofteWare{" "}
              </button>
            </div>
            <div>
              <button className="hover:text-white hover:bg-black bg-[#f6f6f6] p-2 text-black text-left text-xs w-44 py-4 font-semibold ">
                {">  "}
                Mobile Apps{" "}
              </button>
            </div>
            <div>
              <button className="hover:text-white hover:bg-black bg-[#f6f6f6] p-2 text-black text-left text-xs w-44 py-4 font-semibold ">
                {">  "}
                Web Services{" "}
              </button>
            </div>
            <div>
              <button className="hover:text-white hover:bg-black bg-[#f6f6f6] p-2 text-black text-left text-xs w-44 py-4 font-semibold ">
                {">  "}
                Digital Marketing
              </button>
            </div>
            </div>
          </div>
          <div>


          <div className=" font-semibold text-2xl mb-4 " >TAG</div>
          <div className="flex-col flex gap-1 ">
            <div>
              <button className="hover:text-white rounded-full  hover:bg-black bg-[#f6f6f6] p-2 text-black text-centert text-xs w-44 py-4 font-semibold ">
                
                HTML {" "}
              </button>
            </div>
            <div>
              <button className="hover:text-white rounded-full hover:bg-black bg-[#f6f6f6] p-2 text-black text-centert text-xs w-44 py-4 font-semibold ">
                
      CSS{" "}
              </button>
            </div>
            <div>
              <button className="hover:text-white rounded-full hover:bg-black bg-[#f6f6f6] p-2 text-black text-centert text-xs w-44 py-4 font-semibold ">
               
                Tailwind {" "}
              </button>
            </div>
            <div>
              <button className="hover:text-white rounded-full hover:bg-black bg-[#f6f6f6] p-2 text-black text-centert text-xs w-44 py-4 font-semibold ">
          
                React js
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
      <SwiperAbout />
      <Testimonial />
    </div>
  );
};

export default ServicesDetail;
