import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Input = (props) => {
    const { label, name, placeholder, ...rest } = props
    return (
        <>
            <div className='flex flex-col gap-1 w-full' >
                <label className='text-[#fff] font-thin' htmlFor={name}>{label}</label>
                <Field
                    className=" bg-[#f8f8f8] text-black border-collapse border-1 font-thin border-gray-400  placeholder:italic placeholder:text-sm placeholder:text-slate-400   focus:outline-none focus:border-black   focus:border-transparent focus:ring-1"
                    id={name}
                    name={name}
                    {...rest}
                    placeholder={placeholder}
                />
                <ErrorMessage name={name} component={TextError} />
            </div>
        </>
    )
}

export default Input

