import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <div>
      <div>
<div class="bg-gray-700 p-3 space-y-10 md:grid grid-cols-2 md:-mt-6 lg:grid-cols-4">
    <div class="w-1/2 md:ml-24 md:mt-9 md:w-3/4">
        <h1 class="text-white text-xl font-medium"> Aanshi Solutions</h1> <br />
        <p class="text-gray-300/80 text-base hover:text-[#039ee3] cursor-none"> 
        A software solution provider company with a view to digitise the day to day activities of the organisations varying from all the major sectors of business houses.
 </p> <br />
       <div className="flex">

         </div>
    </div>
    <div class="leading-9 md:w-2/4 md:order-3 md:ml-24">
        <h1 class="text-white text-xl font-medium tracking-[0.030rem]">  Links </h1>
        <ul class="mt-2 text-gray-300/80">
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="about" class="hover:text-[#039ee3]">
            About Us </Link> </li>
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="terms" class="hover:text-[#039ee3]">
            Terms and conditions </Link> </li>
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="privacypolicy" class="hover:text-[#039ee3]">
            Privacy policy</Link> </li>
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="contact" class="hover:text-[#039ee3]">
            Contact us</Link> </li>

        </ul>
    </div>
    <div class="leading-9 md:w-3/4 md:order-4">
        <h1 class="text-white text-xl font-medium tracking-[0.030rem]">  Services </h1>
        <ul class="mt-2 text-gray-300/80">
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="#" class="hover:text-[#039ee3]"> 
            Softwares</Link> </li>
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="#" class="hover:text-[#039ee3]"> 
            Mobile apps</Link> </li>
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="#" class="hover:text-[#039ee3]">
            Web Solutions
</Link> </li>
            <li> <i class="fa fa-chevron-right text-[#039ee3]"></i> <Link to="#" class="hover:text-[#039ee3]">
            Digital Marketing
</Link> </li>
           
        </ul>
    </div>
    <div class="md:order-2 lg:order-last">
        <h1 class="text-white text-xl font-medium tracking-[0.030rem]"> Contact Us </h1> <br />
        <p class="text-gray-300/80 w-3/5 leading-7 mb-0 md:w-3/4">  AANSHI SOLUTIONS </p>
        <p class="text-gray-300/80 w-3/5 leading-7 mb-0 md:w-3/4">205,Prakash Tower
         Y.N.Road,Indore(m.p)-452001 </p>
         <p class="text-gray-300/80 w-3/5 leading-7 mb-0 md:w-3/4">  info@aanshisolutions.com  </p>
         <p class="text-gray-300/80 w-3/5 leading-7 mb-5 md:w-3/4"> +91-9827072993,+91-9755322022  </p>
         

            <input type="email" placeholder="Enter E-mail Here"
            class="py-1 px-2 placeholder-gray-400 rounded-tl-xl focus: outline-none focus:border border-[#039ee3] md:w-1/2 lg:w-3/5" />
        <button class="text-white bg-[#039ee3] p-1 -translate-x-1 rounded-br-xl hover:bg-[#039ee3]"> Subscribe
        </button>
    </div>
</div>
<div class="bg-[#039ee3] p-3 text-white text-center  md:space-y-0">
   
Copyright © Aanshi Solutions, 2023
</div>
</div>

      </div >
    </>
  );
};

export default Footer;
