import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdMarkEmailRead } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import Button from "./Button";
// import logo from '../assets/ColorLogo.png'
import { motion } from "framer-motion";
import { BsYoutube, BsTwitter, BsCart } from "react-icons/bs";
import { DiGithubAlt } from "react-icons/di";
import { AiOutlineClose } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaTelegramPlane, FaLinkedinIn } from "react-icons/fa";
// import { useMyCartQuery } from "../services/profile";
// import { getToken } from "../services/studentStorage";
import ButtonLogout from "./ButtonLogout";
// import { removeToken } from "../services/LocalStorage";
// import { useNavigate } from "react-router-dom";
// import { useGetStudentProfileQuery } from "../services/profile";
// import img from "../assets/Unknown.png";

const Header = ({ sticky }) => {
  // const navigate = useNavigate();
  // const token = getToken("token");
  // const { data } = useMyCartQuery(token);
  // console.log(data);
  const [open, setOpen] = useState(false);
  const LogOut = () => {
    // removeToken("token");
    // navigate("/signup/login");
  };
  // const { data: Data } = useGetStudentProfileQuery(token);

  return (
    <>
      <div className="text-black" > 
        {/* Top Portion */}
        <motion.div
          animate={{ scale: 1 }}
          initial={{ scale: 3 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-[#007bff] text-black h-[50px] hidden md:block text-sm  font-body">
            <div className="w-full h-full flex justify-between items-center text-white px-20 ">
              <motion.div
                animate={{ scale: 1 }}
                initial={{ scale: 0 }}
                transition={{ duration: 1 }}
                className="flex gap-2 items-center"
              >
                <span className="text-xl">
                  <MdMarkEmailRead />
                </span>
                Email :
                <a className=" text-sm text-white tracking-wide" href="mailto:info@"> <u> info@aanshisolutions.com </u> </a>

              </motion.div>

              <motion.div
                animate={{ scale: 1 }}
                initial={{ scale: 0 }}
                transition={{ duration: 1 }}
                className="flex  gap-6 text-lg"
              >
                <Link
                  to="https://www.facebook.com"
                  target="_blank"
                  title="facebook"
                  className="text-white hover:text-gold hover:scale-125 duration-300 "
                >
                  <FaFacebookF />
                </Link>

                <Link
                  to="https://github.com/"
                  target="_blank"
                  title="github"
                  className="duration-300 text-white text-xl hover:scale-125 hover:text-gold"
                >
                  <DiGithubAlt />
                </Link>

                <Link
                  to="https://www.instagram.com//"
                  target="_blank"
                  title="Instagram"
                  className="duration-300 text-white hover:text-gold hover:scale-125 "
                >
                  <AiFillInstagram />
                </Link>
                <Link
                  to="https://www.youtube.com/"
                  target="_blank"
                  className="duration-300 text-white hover:text-gold hover:scale-125 "
                >
                  <BsYoutube />
                </Link>
                <Link
                  to="https://twitter.com"
                  target="_blank"
                  title="twitter"
                  className="duration-300 text-white hover:text-gold hover:scale-125 "
                >
                  <BsTwitter />
                </Link>
                <Link
                  target="_blank"
                  to="https://t.me/"
                  className="duration-300 text-white hover:text-gold hover:scale-125 "
                >
                  <FaTelegramPlane />
                </Link>
                <Link
                  to="https://www.linkedin.com/"
                  target="_blank"
                  title="LinkedIn"
                  className="duration-300 text-white hover:text-gold hover:scale-125 "
                >
                  <FaLinkedinIn />
                </Link>
              </motion.div>
            </div>
          </div>
        </motion.div>

        {/* <Header /> */}
        <header
          className={` ${sticky
            ? "sticky md:h-16  transition-all md:px-20 md:opacity-80 md:bg-white z-30 "
            : null
            }  flex justify-between items-center px-4 md:px-12 h-24 w-full 
            bg-white duration-700`}
        >
          <NavLink to="/">
            <img className="h-24 w-24 text-white  " src="LOGO.png" alt="" />
          </NavLink>

          <nav className="z-30 ">
           
          {open ? (
                <button onClick={() => setOpen(!open)} className="md:hidden">
                  <AiOutlineClose className="h-8 w-8 text-black" />
                </button>
              ) : (
                <button onClick={() => setOpen(!open)} className="md:hidden">
                  <GiHamburgerMenu className="h-8 w-8 text-black" />
                </button>
              )}
            <ul
              className={`${sticky ? "md:bg-white z-30" : ""} ${open ? "translate-x-0" : "translate-x-full"
                } fixed left-0
                right-0 min-h-screen bg-white/80 text-black space-y-4 p-4  md:relative md:flex md:min-h-0 md:space-y-0 md:space-x-6 md:p-0 md:translate-x-0 transition duration-200 transfrom items-center `}
            >
              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.2 }}
                className="hover:text-pink-first"
                onClick={() => setOpen(!open)}
              >
                <NavLink to="/">Home</NavLink>
              </motion.li>

              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="hover:text-pink-first"
                onClick={() => setOpen(!open)}
              >
                <NavLink to="about">About Us</NavLink>
              </motion.li>

              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4 }}
                className="hover:text-pink-first"
                onClick={() => setOpen(!open)}
              >
                <NavLink to="contact">Contact</NavLink>
              </motion.li>

              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className="hover:text-pink-first"
              >
                <NavLink to="service" onClick={() => setOpen(!open)}>
                  <button>Services </button>
                </NavLink>
              </motion.li>

           
              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="hover:text-pink-first"
              >
                <NavLink to="blog" onClick={() => setOpen(!open)}>
                 <button>Blog</button>
                </NavLink>
              </motion.li>
              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className="hover:text-pink-first"
              >
                <NavLink to="solution" onClick={() => setOpen(!open)}>
                  <button>Solutions </button>
                </NavLink>
              </motion.li>
              <motion.li
                initial={{ y: "-5vh", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className="hover:text-pink-first"
              >
                <NavLink to="career" onClick={() => setOpen(!open)}>
                  <button>Career</button>
                </NavLink>
              </motion.li>

             

             
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Header;
