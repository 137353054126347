import React, { useState, useEffect } from 'react'
import Images from './images'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperNavButton from '../components/SwiperNavButton';
import { IoReaderOutline } from 'react-icons/io5';
import news from '../assets/news.jpg'
import press from '../assets/pressvarta.png'
import check from '../assets/checkmate.jpg'
import presslogo from '../assets/presslogo.jpeg'
import oh from '../assets/transparent.png'
import { Link } from 'react-router-dom';

const BelifInActionHome = () => {
    return (
        <>
            <div className='bg-black text-white ' >
                <div className='py-4 flex flex-col w-11/12 gap-2 m-auto justify-start items-start' >
                    <h2 className='  text-3xl text-semibold ' > Our Works</h2>
                    <p className=' font-thin text-white/50' >Take a Tour to our Products, We deliver Innovative Robust and Result Oriented Business IT Solutions.</p>
                </div>
                <Swiper
                    // install Swiper modules
                    speed="10000"
                    autoplayDisableOnInteraction={false}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    // spaceBetween={20}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    parallax={true}
                    // slidesPerView={perView}
                    // navigation
                    // pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log('slide change')}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                    className="bg-black w-11/12 m-auto"
                >

                    <SwiperSlide>
                        <div className='h-[80vh] overflow-hidden  group' >

                            <div className='h-full w-full rounded-md bg-news bg-cover  bg-no-repeat  flex  items-end  ' >
                                <img className='absolute h-24 top-10 group-hover:scale-125 duration-300 left-1/2 -translate-x-1/2' src={press} alt="" />
                                <div className='flex flex-col gap-3 p-5 group-hover:scale-105 duration-1000 group-hover:bg-black/70 bg-black/20' >
                                    <h2 className='text-3xl font-semibold' >Press Varta</h2>
                                    <h2 className='text-4xl font-bold' >Android</h2>
                                    <p className='text-xl ' >Social Media puts the “public” into PR and the “market” into marketing.  </p>
                                    <div className='flex items-center gap-2 ' >
                                        <IoReaderOutline className='text-pink-first cursor-pointer' />
                                        <Link to="#" >
                                            <span className='bg-gradient-to-r from-pink-first to-pink-second text-transparent bg-clip-text' >READ MORE</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='h-[80vh] overflow-hidden  group' >

                            <div className='h-full w-full rounded-md bg-check bg-cover  bg-no-repeat  flex  items-end  ' >
                                <img className='absolute h-16 top-10 group-hover:scale-125 rounded-sm duration-300 left-1/2 -translate-x-1/2' src={presslogo} alt="" />

                                <div className='flex flex-col gap-3 p-5 group-hover:scale-105 duration-1000 group-hover:bg-black/70 bg-black/20' >
                                    <h2 className='text-3xl font-semibold' >Checkmate</h2>
                                    <h2 className='text-4xl font-bold' >Website</h2>
                                    <p className='text-xl ' >A blog is only as interesting as the interest shown in others. </p>
                                    <div className='flex items-center gap-2 ' >
                                        <IoReaderOutline className='text-pink-first cursor-pointer' />
                                        <span className='bg-gradient-to-r from-pink-first to-pink-second text-transparent bg-clip-text' >READ MORE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='h-[80vh] overflow-hidden  group' >

                            <div className='h-full w-full rounded-md bg-tech bg-cover  bg-no-repeat  flex  items-end  ' >
                                <img className='absolute h-24 top-10 group-hover:scale-125 duration-300 left-1/2 -translate-x-1/2' src="logo.svg" alt="" />
                                <div className='flex flex-col gap-3 p-5 group-hover:scale-105 duration-1000 group-hover:bg-black/70 bg-black/20' >
                                    <h2 className='text-3xl font-semibold' >Online Hoja</h2>
                                    <h2 className='text-4xl font-bold' >Latest Technology</h2>
                                    <p className='text-xl ' >The Web as I envisaged it, we have not seen it yet. The future is still so much bigger than the past.  </p>
                                    <div className='flex items-center gap-2 ' >
                                        <IoReaderOutline className='text-pink-first cursor-pointer' />
                                        <span className='bg-gradient-to-r from-pink-first to-pink-second text-transparent bg-clip-text' >READ MORE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='h-[80vh] overflow-hidden  group' >

                            <div className='h-full w-full rounded-md bg-inventory bg-cover  bg-no-repeat  flex  items-end  ' >
                                <img className='absolute h-24 top-10 group-hover:scale-125 duration-300 left-1/2 -translate-x-1/2' src="logo.svg" alt="" />

                                <div className='flex flex-col gap-3 p-5 group-hover:scale-105 duration-1000 group-hover:bg-black/70 bg-black/20' >
                                    <h2 className='text-3xl font-semibold' >Inventory</h2>
                                    <h2 className='text-4xl font-bold' >onlinehoja</h2>
                                    <p className='text-xl ' >Don't kid yourself. Be honest with yourself. Take your own inventory. </p>
                                    <div className='flex items-center gap-2 ' >
                                        <IoReaderOutline className='text-pink-first cursor-pointer' />
                                        <span className='bg-gradient-to-r from-pink-first to-pink-second text-transparent bg-clip-text' >READ MORE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    {/* <SwiperSlide>
                        <div className='h-[80vh] overflow-hidden  group' >

                            <div className='h-full w-full rounded-md bg-tech bg-cover  bg-no-repeat  flex  items-end  ' >
                                <div className='flex flex-col gap-3 p-5 group-hover:scale-105 duration-1000 group-hover:bg-black/70 bg-black/20' >
                                    <h2 className='text-3xl font-semibold' >A new, nimble</h2>
                                    <h2 className='text-4xl font-bold' >NWC</h2>
                                    <p className='text-xl ' >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem  </p>
                                    <div className='flex items-center gap-2 ' >
                                        <IoReaderOutline className='text-pink-first cursor-pointer' />
                                        <span className='bg-gradient-to-r from-pink-first to-pink-second text-transparent bg-clip-text' >READ MORE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide> */}


                    <div className='pt-10' >
                        <SwiperNavButton />
                    </div>
                </Swiper>

            </div>
        </>
    )
}

export default BelifInActionHome
