import React, { useEffect } from "react";
// import block from "../assets/block.png";
// import node from "../assets/node.png";
import digital from "../assets/digital1.jpg";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import mobileapp from "../assets/mobile-apps.jpg";
import software from "../assets/software.png";
import customsoftware from "../assets/customsoftware.webp";
import graphic from "../assets/graphic-design4.png";
// import { Link } from "react-router-dom";
import websiteservice from "../assets/websiteservice.png";
// import { useGetTechnoQuery } from "../services/profile";
import Loader from "./Loader";
import { Image } from "antd";

const MainPageCard = () => {
  // const { data, isLoading } = useGetTechnoQuery();
  // console.log(data, "15");
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-100vh",
        opacity: 0,
      });
    }
  }, [animation, inView]);

  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <motion.div className="flex   items-center justify-center bg-white flex-col py-2  ">
          <div class="flex items-center justify-center text-center  w-[100%]">
            <div class="flex flex-col items-center justify-center rounded-tr-full rounded-bl-full w-full bg-gray-100">
              <div class="flex flex-col p-2 m-2 w-full">
                <div class="text-xl md:text-3xl font-medium">
                  {" "}
                  OUR SERVICES{" "}
                </div>
                <div className="text-center">
                  Fastest service with best price!{" "}
                </div>

                <div class="sm:grid sm:px-20 sm:grid-cols-6 sm:gap-3 md:grid-cols-3 md:grid lg:grid-cols-6 lg:grid grid-cols-2 grid  gap-4 p-2">
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="sm:h-28 sm:w-32 m-auto     rounded-full overflow-hidden bg-white">
                      <Image
                        preview={false}
                        src={software}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{ borderRadius: "50%", margin: "auto" }}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Ready to use Softwares
                    </div>
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  border-1  rounded-2xl bg-white hover:scale-105 w-[100%] ">
                    <div class="h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                        preview={false}
                        src={customsoftware}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{ borderRadius: "50%", margin: "auto" }}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Customised Software Development
                    </div>
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl border-collapse border-1  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                        preview={false}
                        src={mobileapp}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{ borderRadius: "50%", margin: "auto" }}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Mobile Apps
                    </div>
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="h-28 sm:w-32 m-auto p-10  rounded-full overflow-hidden bg-white">
                      <img src={websiteservice} alt="" class="" />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Web Services
                    </div>
                  </div>
                  <div class="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div class="h-28 sm:w-32 m-auto     rounded-full overflow-hidden bg-white">
                      <Image
                        preview={false}
                        src={graphic}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{ borderRadius: "50%", margin: "auto" }}
                      />
                    </div>

                    <div class="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Graphic Designing
                    </div>
                  </div>
                  <div className="flex-col px-6 py-2 shadow-2xl  rounded-2xl hover:scale-105 w-[100%] bg-white ">
                    <div className="h-28 sm:w-32 m-auto   rounded-full overflow-hidden bg-white">
                      <Image
                        preview={false}
                        src={digital}
                        alt=""
                        class=""
                        width={100}
                        height={100}
                        style={{ borderRadius: "50%", margin: "auto" }}
                      />
                    </div>

                    <div className="text-sm font-medium text-stone-600 cursor-pointer hover:text-stone-400">
                      Digital Marketing
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default MainPageCard;
