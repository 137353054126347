import React from "react";
import { BsArrowDownShort } from "react-icons/bs";
import education from "../assets/education1.jpg";
import {
  BsCart4,
  BsHandbagFill,
  BsFillChatRightTextFill,
  BsCalculator,
  BsFillBuildingsFill,
  BsFillCalendarCheckFill,
  BsFillBuildingFill,
  BsFillCarFrontFill,
  BsFileEarmarkMusic,
} from "react-icons/bs";
import { RiComputerFill } from "react-icons/ri";
import { GiAmericanFootballHelmet } from "react-icons/gi";
import { FcAndroidOs } from "react-icons/fc";
import { FaHeartbeat, FaBed } from "react-icons/fa";
import { MdCastForEducation } from "react-icons/md";
import { SiInteractiondesignfoundation } from "react-icons/si";
import web from "../assets/website1.jpg";
import android from "../assets/android2.jpg";
import ecom from "../assets/ecomerce1.jpg";
import ui from "../assets/ui.jpg";
import health from "../assets/health.jpg";
import cart from "../assets/cart.jpg";
import hospitality from "../assets/hospitality.jpg";
import social from "../assets/social.jpg";
import accounting from "../assets/accounting.jpg";
// import realestate from "../assets/realestate.jpg";
import client from "../assets/CLIENT.jpg";
import spots from "../assets/sports1.jpg";
import car from "../assets/mustang.jpg";
import music from "../assets/music.jpg";
import { Link } from "react-router-dom";
// import { useGetServiceQuery } from "../services/profile";
import Loader from "./Loader";



const ServiceHome = () => {
//   const { data, isLoading } = useGetServiceQuery();
//   console.log(data, "30");
  return (
    <>
      {false ? (
        <Loader />
      ) : (
        <div class="flex justify-center bg-blue-500 p-4">
        <div class="flex flex-col justify-center items-center ">
            <div class="text-white text-3xl font-medium uppercase">Our Trusted Clients</div>
            <div class="sm:grid sm:grid-cols-3 grid grid-cols-1  gap-2 px-4 py-4 ">

                <div class="overflow-hidden w-full m-0 flex justify-center bg-white rounded-lg  md:w-[100%] px-8">

                    <div class="flex flex-col md:flex-row items-center justify-center  ">
                        <div class="  items-center justify-center flex py-2">
                            <div class="flex flex-col  items-center justify-center text-center">
                                <img src={client} alt="" class="rounded-full h-36 w-36" />
                                <div class="text-stone-600 font-medium m-2"> Thanks Aanshi Solutions for providing us the best IT solutions with latest technology tools and ease our daily business operations.</div>
                                <div class="font-bold uppercase">sHUBHAM ROADLINES</div>
                                {/* <div class="text-cyan-600 italic"><a href="#">Board Director of Tailblocks</a></div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="overflow-hidden w-full m-0 flex justify-center bg-white rounded-lg  md:w-[100%] px-8">

                    <div class="flex flex-col md:flex-row items-center justify-center  ">
                        <div class="  items-center justify-center flex py-2">
                            <div class="flex flex-col  items-center justify-center text-center">
                            <img src={client} alt="" class="rounded-full h-36 w-36" />
                                <div class="text-stone-600 font-medium m-2"> Aanshi Solutions has made our complex daily transporting to easiest working style with flexible and online software solution.</div>
                                <div class="font-bold uppercase">National Cargo Movers</div>
                                {/* <div class="text-cyan-600 italic"><a href="#">MD of Tailblocks</a></div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="overflow-hidden w-full m-0 flex justify-center bg-white rounded-lg  md:w-[100%] px-8">

                    <div class="flex flex-col md:flex-row items-center justify-center  ">
                        <div class="  items-center justify-center flex py-2">
                            <div class="flex flex-col  items-center justify-center text-center">
                            <img src={client} alt="" class="rounded-full h-36 w-36" />
                                <div class="text-stone-600 font-medium m-2"> We are delighted and thankful to Aanshi Solutions for software solutions and management tools provided to us with customisations done as per our working style.Thanks Aanshi solutions.</div>
                                <div class="font-bold uppercase">Associated Transport,Hyderabad</div>
                                {/* <div class="text-cyan-600 italic"><a href="#">Board Director of Tailblocks</a></div> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> 
      )}
    </>
  );
};

export default ServiceHome;
