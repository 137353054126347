import React from 'react'
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { BsApple, BsFacebook, BsInstagram, BsYoutube, BsTelegram, BsLinkedin } from 'react-icons/bs';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
const Whyonlinehoja = () => {
    return (
        <>
            <div className='bg-black text-white  py-4 grid gap-10'>
                <div className='grid grid-cols-1 md:grid-cols-2 w-10/12 m-auto gap-8 ' >


                    <div className='grid gap-3' >
                        <span>Developer Expertise</span>
                        <p className='font-bold' >We understand that every student has different needs and capabilities, which is why we create such a wonderful and unique curriculum that is the best fit for every student.</p>
                    </div>
                    <div className='grid justify-items-start gap-2' >

                        <div className='flex bg-black border rounded-md py-1 px-2 gap-2' >
                            <div className='grid items-center' >
                                <IoLogoGooglePlaystore className='text-4xl text-[#996515]' />
                            </div>
                            <div className='flex flex-col' >
                                <span className='text-xs' >
                                    GET IT ON
                                </span>
                                <span className='text-xl font-semibold' >
                                    Google Play
                                </span>
                            </div>
                        </div>

                        <div className='flex bg-black border rounded-md py-1 px-2 gap-2' >
                            <div className='grid items-center' >
                                <BsApple className='text-4xl text-[#996515]' />
                            </div>
                            <div className='flex flex-col' >
                                <span className='text-xs' >
                                    GET IT ON
                                </span>
                                <span className='text-xl font-semibold' >
                                    Google Play
                                </span>
                            </div>
                        </div>

                        <div className='grid gap-3' >
                            <span>Follow Us:</span>
                            <div className='flex  md:gap-10 gap-4 text-xl' >

                                <Link
                                    to="https://www.facebook.com/onlinehoja"
                                    target="_blank"
                                    title="facebook">
                                    <span className='text-blue-500 bg-white rounded-full' ><BsFacebook /></span>
                                </Link>

                                <Link
                                    to="https://www.instagram.com/onlinehoja.in/"
                                    target="_blank"
                                >
                                    <span className='text-red-700 bg-white rounded-full' ><BsInstagram /></span>
                                </Link>
                                <Link
                                    to="https://www.youtube.com/@OnlineHoja"
                                    target="_blank">
                                    <span className='text-white bg-red-500 ' ><BsYoutube /></span>
                                </Link>
                                <Link
                                    to="https://twitter.com/OnlineHoja"
                                    target="_blank"
                                    title="twitter">
                                    <span className='text-blue-400 bg-white rounded-full' ><AiFillTwitterCircle /></span>
                                </Link>
                                <Link
                                    target="_blank"
                                    to="https://t.me/onlinehoja">
                                    <span className='text-blue-700 bg-white rounded-full' ><BsTelegram /></span>
                                </Link>

                                <Link
                                    className='text-white'
                                    target="_blank"
                                    to="https://www.linkedin.com/company/90743787/admin/">
                                    <span className='text-blue-700 bg-white rounded-full' ><BsLinkedin className='bg-white text-blue-700' /></span>
                                </Link>

                            </div>
                        </div>

                    </div>
                </div>


                <div className='w-10/12 m-auto grid gap-4' >
                    <span className='text-2xl font-thin text-blue-500' >Know about Developer Expertise</span>
                    <p className='text-thin text-white/80' >Our team of experienced developers is equipped with the latest tools and technologies to deliver exceptional results. With a deep understanding of the web development landscape, our experts are capable of bringing your vision to life with precision and innovation. From creating stunning websites to developing robust applications, we pride ourselves on our ability to meet the unique needs of each of our clients.</p>
                    <p className='text-thin text-white/80' > Get to know our team and their diverse skill sets, and see how our expertise can take your business to new heights.</p>
                </div>

                <div className='w-10/12 m-auto grid gap-4' >
                    <span className='text-2xl font-thin  text-blue-500' >We Stand Out because</span>
                    <p className='text-thin text-white/80' >We believe that our success is not just measured by the number of projects we complete but by the level of satisfaction we bring to our clients. Our commitment to excellence is unmatched and we take pride in delivering exceptional results every time. When you work with us, you can expect nothing less than excellence. That's what sets us apart and that's why we stand out.</p>
                </div>

                <div className='w-10/12 m-auto grid gap-4' >
                    <span className='text-2xl font-thin  text-blue-500' >Our Key Focus Areas</span>
                    <p className='text-thin text-white/80' >
                        Our dedication to quality, attention to detail, and passion for innovation is what truly makes us different. Whether you are looking to develop a cutting-edge website or want to take your business to the next level with blockchain technology, we are here to help. Choose Online Hoja, and experience the difference that comes with working with a team that truly cares about your success.</p>
                </div>

                <div className='w-10/12 m-auto grid gap-4' >
                    <span className='text-2xl font-thin  text-blue-500' >What Makes Us Different</span>
                    <p className='text-thin text-white/80' >At Online Hoja, we believe that what sets us apart is our unwavering commitment to excellence in all that we do. Our team of experts is not only highly skilled and experienced in the latest technologies such as React.js, Node.js, MongoDB, and blockchain, but we also go above and beyond to ensure that our clients receive the personalized attention and care they deserve.
                    </p>
                </div>

            </div>
        </>
    )
}

export default Whyonlinehoja
